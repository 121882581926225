/**
 * @author Rishabh
 */
import { BasePageTemplate, TagManager } from "@accor/ace-ui-core";
import {
  PAGE_LOYALTY,
  PAGE_MAGAZINE_HUB,
  PAGE_MAGAZINE_ARTICLE,
  PAGE_MAGAZINE_CATEGORY,
} from "../../../../../utils/tracking";

export default class NovotelBasePageTemplate extends BasePageTemplate {
  /** @inheritDoc */
  constructor(componentHost) {
    super(componentHost);
    this.basePageTagManagerPush();
  }

  /**
   * Pushing tagmanager data on various components as per requirement (components who do not have js classes registered)
   */
  basePageTagManagerPush() {
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }

    // For loyalty billboard tag manager push on various templates
    if (
      [
        PAGE_MAGAZINE_HUB,
        PAGE_MAGAZINE_CATEGORY,
        PAGE_MAGAZINE_ARTICLE,
      ].includes(this.dataLayerJson?.pageName)
    ) {
      document
        ?.querySelectorAll(
          '.billboard [class*="ace-billboard__full"] .ace-button a',
        )
        ?.forEach((button) => {
          button?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            this.pushBillboardLoyaltyTagmanager();
          });
        });
    }

    // For Highlight tag manager push on various templates
    if (this.dataLayerJson?.pageName === PAGE_MAGAZINE_HUB) {
      document
        ?.querySelectorAll(
          ".highlight .ace-highlight .ace-highlight__content .ace-button a",
        )
        ?.forEach((button) => {
          button?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            this.pushHighlightTagmanager(event);
          });
        });
    }

    // For Article cta tag manager push on various templates
    if (this.dataLayerJson?.pageName === PAGE_MAGAZINE_ARTICLE) {
      document
        ?.querySelectorAll(
          '[class*="article-align-"] .call-to-action .call-to-action .ace-button a',
        )
        ?.forEach((button) => {
          button?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            this.pushArticleCtaTagmanager();
          });
        });

      document
        ?.querySelectorAll(".sharepage ul li .share__link")
        ?.forEach((anchor) => {
          anchor?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            const classListItems =
              event?.target?.classList?.value?.split("--")[1];
            let shareElement = classListItems;
            if (classListItems === "button") {
              shareElement = "link";
            }
            this.pushArticleShareCtaTagmanager(shareElement);
          });
        });
    }

    // For heading cta tag manager push on various templates
    if (this.dataLayerJson?.pageName === PAGE_LOYALTY) {
      document
        ?.querySelectorAll(".ace-headingpagehero-headline a")
        ?.forEach((button, index) => {
          button?.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            this.pushheadingCtaTagmanager(event, index);
          });
        });
    }
  }

  /**
   * Pushing loyalty billboard data to tagmanager
   */
  pushBillboardLoyaltyTagmanager() {
    const dataEvent = {
      event: "GA4event",
      eventName: "bloc_interact",
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: "all program",
        bloc_interaction: "become a member",
      },
    };
    if (this.dataLayerJson?.pageName === PAGE_MAGAZINE_CATEGORY) {
      dataEvent.event_data.bloc_name = document
        .querySelector(".heading-hero .cmp-headingpagehero-title")
        ?.innerText?.trim()
        ?.toLowerCase();
      dataEvent.event_data.bloc_interaction = "all program";
      dataEvent.event_data.bloc_type = "become a member";
    }
    if (this.dataLayerJson?.pageName === PAGE_MAGAZINE_ARTICLE) {
      dataEvent.event_data.bloc_name = document
        .querySelector(".heading-hero .cmp-headingpagehero-title")
        ?.innerText?.trim()
        ?.toLowerCase();
      dataEvent.event_data.bloc_interaction = "all program";
      dataEvent.event_data.bloc_type = "become a member";
    }
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing Article cta data to tagmanager
   */
  pushArticleCtaTagmanager() {
    const dataEvent = {
      event: "GA4event",
      eventName: "bloc_interact",
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: document
          .querySelector(".heading-hero .cmp-headingpagehero-title")
          ?.innerText?.trim()
          ?.toLowerCase(),
        bloc_interaction: "check rates",
      },
    };
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing Highlight cta data to tagmanager
   * @param {HTMLElement} event The target element where action performed
   */
  pushHighlightTagmanager(event) {
    const dataEvent = {
      event: "GA4event",
      eventName: "bloc_interact",
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: event?.target
          ?.closest(".ace-highlight__content")
          ?.querySelector(".ace-highlight__content--title")
          ?.innerText?.trim()
          ?.toLowerCase(),
        bloc_interaction: event?.target?.innerText?.trim()?.toLowerCase(),
      },
    };
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing Share component cta data to tagmanager
   * @param {String} shareElement cta type
   */
  pushArticleShareCtaTagmanager(shareElement) {
    const dataEvent = {
      event: "GA4event",
      eventName: "bloc_interact",
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: document
          .querySelector(".heading-hero .cmp-headingpagehero-title")
          ?.innerText?.trim()
          ?.toLowerCase(),
        bloc_interaction: `share ${shareElement}`,
      },
    };
    TagManager.trackEvent(dataEvent);
  }

  /**
   * Pushing heading component cta data to tagmanager
   * @param {Object} event target element
   * @param {Number} index target element count
   */
  pushheadingCtaTagmanager(event, index) {
    const dataEvent = {
      event: "GA4event",
      eventName: "cta_enroll",
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        enroll_context: "loyalty",
        cta_name: event?.target?.innerText?.trim()?.toLowerCase(),
        contexte: index + 1,
      },
    };
    TagManager.trackEvent(dataEvent);
  }
}

CoreJS.BaseComponent.registerComponent(
  BasePageTemplate.CLASS_NAMESPACE,
  NovotelBasePageTemplate,
  true,
);
