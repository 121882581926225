/* eslint-disable no-unused-expressions */
/**
 * Class representing a Novotel Social Media Component instance
 * @author Rishabh
 * @class NovotelSocialMedia
 * @classdesc Novotel Social Media component to inherit the Social Media core component
 * @extends SocialMedia
 * @version 1.0
 */
import { SocialMedia } from "@accor/ace-ui-core";
import {
  handleEventTracking,
  getDataLayer,
  PAGE_MAGAZINE_CATEGORY,
  PAGE_LA_SUITE_NOVOTEL,
} from "../../../../../utils/tracking";

export default class NovotelSocialMedia extends SocialMedia {
  constructor(componentHost) {
    super(componentHost);
    ({ pageName: this.pageName } = getDataLayer());
    this.socialMediaFollowLink = this.componentHost.querySelector(
      ".cmp-socialMedia_cta a",
    );
    this.handleFollowLinkTracking();
  }

  handleFollowLinkTracking() {
    const defaultEventData = {
      node: this.socialMediaFollowLink,
      htmlEventType: CoreJS.DomEventConstants.CLICK,
      eventName: "bloc_interact",
      additionalData: {
        bloc_interaction: "follow us on instagram",
        bloc_type: "follow us",
      },
    };
    const eventDataMap = {
      [PAGE_MAGAZINE_CATEGORY]: {
        ...defaultEventData,
        additionalData: {
          ...defaultEventData.additionalData,
          bloc_name: document
            .querySelector(".heading-hero .cmp-headingpagehero-title")
            ?.innerText?.trim()
            ?.toLowerCase(),
        },
      },
      [PAGE_LA_SUITE_NOVOTEL]: {
        ...defaultEventData,
        additionalData: {
          bloc_name: "instagram",
          bloc_interaction: "follow us",
        },
      },
    };

    handleEventTracking(eventDataMap[this.pageName] ?? defaultEventData);
  }
}

CoreJS.BaseComponent.registerComponent(
  SocialMedia.CLASS_NAMESPACE,
  NovotelSocialMedia,
  true,
);
